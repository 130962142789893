(function ($) {
  "use strict";
  $(function () {
    var hash = document.location.hash;
    if (hash == "#form3") {
      $("#recall-popup").foundation("open");
    } else if (hash == "#form4") {
      $("#sign-up-popup").foundation("open");
    }
  });
})(jQuery);

(function ($) {
  "use strict";
  $(function () {
    let $menuBlocks = $(".js-cell");
    $(document).click(function (e) {
      let $currItem = $(e.target).closest(".header__menu");
      if ($currItem.length !== 0) {
        $currItem.toggleClass("js-opened");
        $(".header").toggleClass("js-opened");
        $menuBlocks.toggleClass("show-for-large");
      }
    });

    jQuery(document).on("click", function (e) {
      let $el = jQuery(e.target).closest(".header");
      if ($el.length) return;
      $(".header__menu").removeClass("js-opened");
      $menuBlocks.addClass("show-for-large");
      $(".header").removeClass("js-opened");
    });
  });
})(jQuery);

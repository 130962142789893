import "slick-carousel";
var initSliders = function ($) {
  "use strict";
  $(function () {
    $(".carousel-main").slick({
      infinite: true,
      dots: false,
      arrows: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplayHoverPause: false,
      fade: true,
      swipeToSlide: true,
      prevArrow:
        '<span><svg class="svg-inline--fa fa-angle-left slick-prev " xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512"><path fill="currentColor" d="M31.7 239l136-136c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9L127.9 256l96.4 96.4c9.4 9.4 9.4 24.6 0 33.9L201.7 409c-9.4 9.4-24.6 9.4-33.9 0l-136-136c-9.5-9.4-9.5-24.6-.1-34z"></path></svg></span>',
      nextArrow:
        '<span><svg class="svg-inline--fa fa-angle-left  slick-next " xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512"><path fill="currentColor" d="M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34z"></path></svg></span>',
      responsive: [
        {
          breakpoint: 640,
          settings: {
            arrows: false,
          },
        },
      ],
    });

    function addLeadZero(num, size) {
      num = num.toString();
      while (num.length < size) num = "0" + num;
      return num;
    }

    function multiplySlides($slider) {
      if ($slider.length === 0) {
        return;
      }
      let innerSlides = $slider.html();
      for (var i = 0; i < 5; i++) {
        innerSlides = innerSlides.concat(innerSlides);
      }
      $slider.html(innerSlides);
    }
    let numOfSlides = $(".consult__item").length;
    $(".consult__num_last").text(addLeadZero(numOfSlides, 2));

    let $consult = $(".consult__slider");
    multiplySlides($consult);
    $consult.slick({
      infinite: true,
      dots: false,
      arrows: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: false,
      autoplayHoverPause: false,
      fade: true,
      swipeToSlide: false,
      swipe: false,
    });
    let $consultInfo = $(".consult__info");
    multiplySlides($consultInfo);
    $consultInfo.slick({
      infinite: false,
      dots: false,
      arrows: true,
      speed: 500,
      centerMode: true,
      slidesToScroll: 1,
      variableWidth: true,
      swipeToSlide: false,
      swipe: false,
      autoplay: true,
      autoplayHoverPause: false,
      fade: false,
      prevArrow:
        '<span class="slick-prev"><svg width="42" height="16" viewBox="0 0 42 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.3716 0.000823373C9.15077 0.0121776 8.94333 0.0944962 8.79277 0.233585L0 8.00271L8.79277 15.7718C9.00356 15.962 9.31806 16.0415 9.61584 15.979C9.91362 15.9166 10.1512 15.7236 10.2348 15.4738C10.3218 15.2212 10.2415 14.9515 10.024 14.767L3.19524 8.72938H40.2433C40.5511 8.73222 40.8389 8.59597 40.9961 8.36888C41.1501 8.1418 41.1501 7.86362 40.9961 7.63654C40.8389 7.40945 40.5511 7.2732 40.2433 7.27604H3.19524L10.024 1.23843C10.275 1.02554 10.3452 0.704785 10.198 0.432284C10.0541 0.156944 9.72625 -0.0133692 9.3716 0.000823373Z" fill="white"/></svg></span>',
      nextArrow:
        '<span class="slick-next"><svg width="42" height="16" viewBox="0 0 42 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.3716 0.000823373C9.15077 0.0121776 8.94333 0.0944962 8.79277 0.233585L0 8.00271L8.79277 15.7718C9.00356 15.962 9.31806 16.0415 9.61584 15.979C9.91362 15.9166 10.1512 15.7236 10.2348 15.4738C10.3218 15.2212 10.2415 14.9515 10.024 14.767L3.19524 8.72938H40.2433C40.5511 8.73222 40.8389 8.59597 40.9961 8.36888C41.1501 8.1418 41.1501 7.86362 40.9961 7.63654C40.8389 7.40945 40.5511 7.2732 40.2433 7.27604H3.19524L10.024 1.23843C10.275 1.02554 10.3452 0.704785 10.198 0.432284C10.0541 0.156944 9.72625 -0.0133692 9.3716 0.000823373Z" fill="white"/></svg></span>',
    });

    $consultInfo.on("beforeChange", function (
      event,
      slick,
      currentSlide,
      nextSlide
    ) {
      let nextNum = (nextSlide % numOfSlides) + 1;

      let widthOfBar = (nextNum / numOfSlides) * 100;
      $(".consult__fill").css("width", widthOfBar + "%");
      $(".consult__num_first").text(addLeadZero(nextNum, 2));
    });
    $consultInfo.on("beforeChange", function (
      event,
      slick,
      currentSlide,
      nextSlide
    ) {
      $consult.slick("slickGoTo", nextSlide);
    });
    $(document).click(function (e) {
      let $currItem = $(e.target).closest(".consult__info .slick-slide");
      if ($currItem.length !== 0) {
        let num = $currItem.data("slick-index");

        $consultInfo.slick("slickGoTo", num);
      }
    });
    $consultInfo.slick("slickGoTo", numOfSlides * 16);

    $(".staff").slick({
      infinite: true,
      arrows: true,
      dots: false,
      slidesToShow: 3,
      slidesToScroll: 1,
      fade: false,
      swipeToSlide: true,
      autoplay: true,
      autoplaySpeed: 7000,
      autoplayHoverPause: true,
      prevArrow:
        '<div class="slick-prev"><svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="50" height="50" rx="25" fill="#93C8C6"/><path fill-rule="evenodd" clip-rule="evenodd" d="M25.7071 17.2929C25.3166 16.9024 24.6834 16.9024 24.2929 17.2929C23.9024 17.6834 23.9024 18.3166 24.2929 18.7071L29.5858 24H18C17.4477 24 17 24.4477 17 25C17 25.5523 17.4477 26 18 26H29.5858L24.2929 31.2929C23.9024 31.6834 23.9024 32.3166 24.2929 32.7071C24.6834 33.0976 25.3166 33.0976 25.7071 32.7071L32.7071 25.7071C33.0976 25.3166 33.0976 24.6834 32.7071 24.2929L25.7071 17.2929Z" fill="white"/></svg></div>',
      nextArrow:
        '<div class="slick-next"><svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="50" height="50" rx="25" fill="#93C8C6"/><path fill-rule="evenodd" clip-rule="evenodd" d="M25.7071 17.2929C25.3166 16.9024 24.6834 16.9024 24.2929 17.2929C23.9024 17.6834 23.9024 18.3166 24.2929 18.7071L29.5858 24H18C17.4477 24 17 24.4477 17 25C17 25.5523 17.4477 26 18 26H29.5858L24.2929 31.2929C23.9024 31.6834 23.9024 32.3166 24.2929 32.7071C24.6834 33.0976 25.3166 33.0976 25.7071 32.7071L32.7071 25.7071C33.0976 25.3166 33.0976 24.6834 32.7071 24.2929L25.7071 17.2929Z" fill="white"/></svg></div>',
      responsive: [
        {
          breakpoint: 850,
          settings: {
            slidesToShow: 2,
          },
        },
        { breakpoint: 640, settings: { slidesToShow: 1 } },
      ],
    });

    $(".carousel-news-anchor").on("click", function (e) {
      e.preventDefault();
      const $this = $(this);
      const index = $this
        .closest(".carousel-news-links")
        .find(".carousel-news-anchor")
        .index($this);
      $(".carousel-news").slick("slickGoTo", index);
    });
  });
};

function runInitSliders() {
  initSliders(jQuery);
  window.removeEventListener("scroll", runInitSliders);
  window.removeEventListener("mousemove", runInitSliders);
  window.removeEventListener("touchmove", runInitSliders);
  window.removeEventListener("resize", runInitSliders);
}

window.addEventListener("scroll", runInitSliders, { passive: true });
window.addEventListener("mousemove", runInitSliders, { passive: true });
window.addEventListener("touchmove", runInitSliders, { passive: true });
window.addEventListener("resize", runInitSliders, { passive: true });

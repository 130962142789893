import "./carousels.js";
import "@fancyapps/fancybox";
import "./fontAwesome.js";
import "./foundation.js";
import "./handleInputNumber.js";
import "./stickyFooter.js";
import "./replaceImgSvg.js";
import "./handleMenuToggle.js";
import "./openModals.js";

import "../scss/style.scss";
(function ($) {
  "use strict";
  $(function () {
    // your code here
  });
})(jQuery);
